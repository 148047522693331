import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

import { mapIndexed } from '../lib/Helpers'

library.add(fab)

export default () => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        title
        main_contact_email
        social_links {
          title
          url
          icon
        }
      }
    }
  `)

  const {
    sanitySiteSettings: { social_links }
  } = data

  return (
    <footer>
      <div className="container">
        {
          // <h4 className="text--center title">
          //   Connect with {data.sanitySiteSettings.title}
          // </h4>
          // <ul className="text--center footer-links">
          //   <li>
          //     <a
          //       href={`mailto:${data.sanitySiteSettings.main_contact_email}`}
          //       className="link link--white"
          //     >
          //       <FontAwesomeIcon icon={faEnvelope} mask={['circle']} size="2x" />
          //     </a>
          //   </li>
          //   {mapIndexed((link, index) => {
          //     return (
          //       <li key={index}>
          //         <a
          //           href={link.url}
          //           className="link link--white"
          //           target="_blank"
          //           rel="noopener noreferrer"
          //         >
          //           <FontAwesomeIcon
          //             icon={['fab', `${link.icon}`]}
          //             className="fb_icon"
          //             size="2x"
          //           />
          //         </a>
          //       </li>
          //     )
          //   })(social_links)}
          // </ul>
        }
        <p className="text--center">
          The appearance of external links on this site does not constitute official endorsement on behalf of the U.S. Air Force or Department of Defense. The Air Force does not endorse any non-federal government organizations, products, or services.
        </p>
        <p className="text--center">
          {new Date().getFullYear()} All Rights Reserved.
        </p>
      </div>
    </footer>
  )
}
